import React from "react";

const Logo = () => {
  function lienUrl(lien) {
    const win = window.open(lien, "_blank");
    win.focus();
    console.log(lien);
  }
  return (
    <>
      <div className="row justify-content-end  content-logo" style={{marginTop:'-2%'}}>
        <div className="col-sm-2 ">
          <input
            type="image"
            src="./img/africatik.png"
            alt=""
            onClick={() => window.open("/africatik", "_blank", "Africatik")}
            className="africatik"
          />
        </div>
      </div>
    </>
  );
};

export default Logo;
