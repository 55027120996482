//import logo from './logo.svg';
import './App.css';
import { Routes,Route,BrowserRouter } from 'react-router-dom';
import Main from '../src/main';
import Module1 from './Pages/Mod1';
import Module4 from './Pages/Mod4';
import Module2 from './Pages/Mod2';
import Module3 from './Pages/Mod3';
import Mapper from './Pages/mapper';
import React from 'react';
import Africatik from './components/Africatik';


function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route exact path='/' element={<Main></Main>} />
    <Route exact path='/module1' element={<Module1/>} />
    <Route exact path='/module2' element={<Module2/>} />
    <Route exact path='/module3' element={<Module3/>} />
    <Route exact path='/module4' element={<Module4/>} />
    <Route exact path='/mapper' element={<Mapper/>} />
    <Route path="/africatik" element={<Africatik></Africatik>} />

   </Routes>
    </BrowserRouter>
    
  );
}

export default App;
