import React, { useEffect, useState } from "react";
import Help from "../components/help";
import Accueil from "../components/home";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import LANG from "../backOffice/properties.json";
import { Howl } from "howler";
import { NativeAudio } from "@capacitor-community/native-audio";
import { NavLink } from "react-router-dom";
import { queryByText } from "@testing-library/react";
import { Alert } from "bootstrap";
import { Modal } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
const Module4 = () =>{
  const [position, setPosition] = useState(0);
  const [position1, setPosition1] = useState(0);
  const [countPoint, setCountPoint] = useState(0);

  sessionStorage.setItem("onglet", "six");
  //Id thème choisie à gerer dynamiquement
  var themeID = parseInt(sessionStorage.getItem("theme"), 10);

  //Id langue à gerer dynamiquement 0=Français, 1=Lingala, 2=Kikongo, 3=Swahili, 4=Tshiluba
  var langID = parseInt(sessionStorage.getItem("Lang"), 10);

  //insertion du titre de la barre de tache
  function Barre_titre(IdLang, IdMod, page, seq) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[IdMod].sequences[
        seq
      ].pages[page].text.toString();
    });
    return data;
  }
  function QuestRep(IdLang, subMod, seq, page, numRep) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[subMod].sequences[seq]
        .pages[page].questions[0].responses[numRep].text;
    });
    return data;
  }
  //Insertion des audios
  function Audios(IdLang, subMod, seq, page) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[subMod].sequences[seq]
        .pages[page].media_pages[0].audio_file;
    });
    return data.toString();
  }
  function Titre_Module(IdLang, IdMod, seq) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[IdMod].sequences[seq]
        .title;
    });
    return data;
  }
  //Insertion de l'image
  function Image(IdLang, seq, page, subMod) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[subMod].sequences[seq]
        .pages[page].media_pages[0].img_file;
    });
    console.log("donnees", data.toString());
    return data.toString();
  }
  //Inserer les msg dans le Popup Dialog

  function FIN_DE_PAGE(Idlangue, Idmodule, seq) {
    const data = LANG.map((info) => {
      return info.languages[Idlangue].domains[0].subdomains[Idmodule].sequences[
        seq
      ].pages[0].text;
    });

    return data;
  }
//
function Bonne_reponse(Idlangue, page){
  const data = LANG.map((info) => {
    return info.languages[Idlangue].domains[0].subdomains[0].sequences[4].pages[
      page
    ].title;
  });
  return data;
}
function Mauvaise_reponse(IdLang,page){
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[4].pages[
      page
    ].title;
  });
  return data;
}

  function preloadAudio(id, path) {
    NativeAudio.preload({
      assetId: id,
      assetPath: path,
      audioChannelNum: 1,
      isUrl: false,
    });
  }

  function stopBtn() {
    NativeAudio.unload({ assetId: "musc1" });
    console.log("btn stop cliqué");
  }
  async function stopAudio(Id) {
    NativeAudio.stop({
      assetId: Id,
    });
  }

  async function handlePlay(id, btnPreload) {
    await document.getElementById(btnPreload).click();
    setTimeout(() => {
      NativeAudio.resume({
        assetId: id,
      });
    }, 200);
  }

  function handlePause(id) {
    NativeAudio.pause({ assetId: id });
  }
  function handleStop(id) {
    stopAudio(id);
  }

  function playPause(music) {
    if (music.playing()) {
      music.pause();
    } else {
      music.play();
    }
  }

  //Afficher le
  function PositionClick(val) {
    setPosition(val);
    setPosition1(val);
  }
  //
  const [open, setOpen] = useState(false);
  const [openSeg, setOpenSeg] = useState(false);
  //
  function handleClickOpen(val, niveau,zone) {
   
    if (niveau == "0") {
      if (countPoint == 0) {
        setCouleur1("#46CC00");
      }
      if (countPoint == 1) {
        setCouleur2("#46CC00");
      }
      if (countPoint == 2) {
        setCouleur3("#46CC00");
        setTimeout(() => {
          setIndex(2);
        }, 1500);
      }
      if (countPoint == 3) {
        setCouleur4("#46CC00");
      }
      if (countPoint == 4) {
        setCouleur5("#46CC00");
      }
      if (countPoint == 5) {
        setCouleur6("#46CC00");
        setTimeout(() => {
          ouvrir();
        }, 2000);
      }
      setCountPoint(countPoint + 1);

      setPosition(val);
      setOpen(true);
      if(zone ==1){
        setZone1(true)
      }if(zone ==2){
        setZone2(true)
      }if(zone ==3){
        setZone3(true)
      }if(zone ==4){
        setZone13(true)
      }
      if(zone ==5){
        setZone14(true)
      }if(zone ==6){
        setZone15(true)
      }

    }
    if (niveau == "1") {
      if (countPoint == 0) {
        setCouleur1("#46CC00");
      }
      if (countPoint == 1) {
        setCouleur2("#46CC00");
      }
      if (countPoint == 2) {
        setCouleur3("#46CC00");
      }
      if (countPoint == 3) {
        setCouleur4("#46CC00");

        setTimeout(() => {
          setIndex(2);
        }, 1500);
      }
      if (countPoint == 4) {
        setCouleur5("#46CC00");
      }
      if (countPoint == 5) {
        setCouleur6("#46CC00");
      }
      if (countPoint === 6) {
        setCouleur7("#46CC00");
      }
      if (countPoint === 7) {
        setCouleur8("#46CC00");
        setTimeout(() => {
          ouvrir();
        }, 2000);
      }
      setCountPoint(countPoint + 1);
      setPosition(val);
      setOpen(true);
      if(zone ==1){
        setZone1(true)
      }if(zone ==2){
        setZone2(true)
      }if(zone ==3){
        setZone3(true)
      }if(zone ==4){
        setZone4(true)
      }if(zone ==5){
        setZone13(true)
      }if(zone ==6){
        setZone14(true)
      }
      if(zone ==7){
        setZone15(true)
      }if(zone ==8){
        setZone16(true)
      }
    }
    if (niveau == "2") {
      if (countPoint == 0) {
        setCouleur1("#46CC00");
      }
      if (countPoint == 1) {
        setCouleur2("#46CC00");
      }
      if (countPoint == 2) {
        setCouleur3("#46CC00");
      }
      if (countPoint == 3) {
        setCouleur4("#46CC00");
      }
      if (countPoint == 4) {
        setCouleur5("#46CC00");
      }
      if (countPoint == 5) {
        setCouleur6("#46CC00");

        setTimeout(() => {
          setIndex(2);
        }, 1500);
      }
      if (countPoint === 6) {
        setCouleur7("#46CC00");
      }
      if (countPoint === 7) {
        setCouleur8("#46CC00");
      }
      if (countPoint === 8) {
        setCouleur9("#46CC00");
      }
      if (countPoint === 9) {
        setCouleur10("#46CC00");
      }
      if (countPoint === 10) {
        setCouleur11("#46CC00");
      }
      if (countPoint === 11) {
        setCouleur12("#46CC00");
        setTimeout(() => {
          ouvrir();
        }, 2000);
      }
      setCountPoint(countPoint + 1);
      setPosition(val);
      setOpen(true);
      
      if(zone ==1){
        setZone1(true)
      }if(zone ==2){
        setZone2(true)
      }if(zone ==3){
        setZone3(true)
      }if(zone ==4){
        setZone4(true)
      }if(zone ==5){
        setZone5(true)
      }if(zone ==6){
        setZone6(true)
      }
      if(zone ==7){
        setZone13(true)
      }if(zone ==8){
        setZone14(true)
      }if(zone ==9){
        setZone15(true)
      }if(zone ==10){
        setZone16(true)
      }if(zone ==11){
        setZone17(true)
      }if(zone ==12){
        setZone18(true)
      }
      //
    }
  }

  function handleClickOpenSeg(val, niveau,zone) {
    
    if (niveau == "0") {
      if (countPoint == 0) {
        setCouleur1("red");
      }
      if (countPoint == 1) {
        setCouleur2("red");
      }
      if (countPoint == 2) {
        setCouleur3("red");
        setTimeout(() => {
          setIndex(2);
        }, 1500);
      }
      if (countPoint == 3) {
        setCouleur4("red");
      }
      if (countPoint == 4) {
        setCouleur5("red");
      }
      if (countPoint == 5) {
        setCouleur6("red");
        setTimeout(() => {
          ouvrir();
        }, 2000);
      }
      setCountPoint(countPoint + 1);
      setPosition1(val);
      setOpenSeg(true);
      if(zone ==1){
        setZone4(true)
      }if(zone ==2){
        setZone5(true)
      }if(zone ==3){
        setZone6(true)
      }if(zone ==4){
        setZone16(true)
      }
      if(zone ==5){
        setZone17(true)
      }if(zone ==6){
        setZone18(true)
      }
      
    }
    if (niveau == "1") {
      if (countPoint == 0) {
        setCouleur1("red");
      }
      if (countPoint == 1) {
        setCouleur2("red");
      }
      if (countPoint == 2) {
        setCouleur3("red");
      }
      if (countPoint == 3) {
        setCouleur4("red");
        setTimeout(() => {
          setIndex(2);
        }, 1500);
      }
      if (countPoint == 4) {
        setCouleur5("red");
      }
      if (countPoint == 5) {
        setCouleur6("red");
      }
      if (countPoint === 6) {
        setCouleur7("red");
      }
      if (countPoint === 7) {
        setCouleur8("red");
        setTimeout(() => {
          ouvrir();
        }, 2000);
      }
      setCountPoint(countPoint + 1);
      setPosition1(val);
      setOpenSeg(true);
      if(zone ==1){
        setZone5(true)
      }if(zone ==2){
        setZone6(true)
      }if(zone ==3){
        setZone7(true)
      }if(zone ==4){
        setZone8(true)
      }if(zone ==5){
        setZone17(true)
      }if(zone ==6){
        setZone18(true)
      }
      if(zone ==7){
        setZone19(true)
      }
    }
    if (niveau == "2") {
      if (countPoint == 0) {
        setCouleur1("red");
      }
      if (countPoint == 1) {
        setCouleur2("red");
      }
      if (countPoint == 2) {
        setCouleur3("red");
      }
      if (countPoint == 3) {
        setCouleur4("red");
      }
      if (countPoint == 4) {
        setCouleur5("red");
      }
      if (countPoint == 5) {
        setCouleur6("red");
        setTimeout(() => {
          setIndex(2);
        }, 1500);
      }
      if (countPoint == 6) {
        setCouleur7("red");
      }
      if (countPoint == 7) {
        setCouleur8("red");
      }
      if (countPoint === 8) {
        setCouleur9("red");
      }
      if (countPoint == 9) {
        setCouleur10("red");
      }
      if (countPoint == 10) {
        setCouleur11("red");
      }
      if (countPoint == 11) {
        setCouleur12("red");
        setTimeout(() => {
          ouvrir();
        }, 2000);
      }
      setCountPoint(countPoint + 1);
      setPosition1(val);
      setOpenSeg(true);
      
      if(zone ==1){
        setZone7(true)
      }if(zone ==2){
        setZone8(true)
      }if(zone ==3){
        setZone9(true)
      }if(zone ==4){
        setZone10(true)
      }if(zone ==5){
        setZone11(true)
      }if(zone ==6){
        setZone12(true)
      }
      if(zone ==7){
        setZone19(true)
      }if(zone ==8){
        setZone20(true)
      }if(zone ==9){
        setZone21(true)
      }if(zone ==10){
        setZone22(true)
      }if(zone ==11){
        setZone23(true)
      }if(zone ==12){
        setZone24(true)
      }
    }
  }
  

  function handleClose() {
    setOpen(false);
    setOpenSeg(false);
    if (countPoint == 12) {
      setTimeout(() => {
        ouvrir();
      }, 1500);
    }
  }
  function show_text_mapping(niveau) {
    var data;
    if (niveau == "0") {
      data = [
        QuestRep(langID, themeID, 3, 0, 0),
        QuestRep(langID, themeID, 3, 0, 1),
        QuestRep(langID, themeID, 3, 0, 2),

        QuestRep(langID, themeID, 3, 1, 0),
        QuestRep(langID, themeID, 3, 1, 1),
        QuestRep(langID, themeID, 3, 1, 2),
       
      ];
    }
    if (niveau == "1") {
      data = [
        QuestRep(langID, themeID, 3, 0, 0),
        QuestRep(langID, themeID, 3, 0, 1),
        QuestRep(langID, themeID, 3, 0, 2),
        QuestRep(langID, themeID, 3, 0, 3),

        QuestRep(langID, themeID, 3, 1, 0),
        QuestRep(langID, themeID, 3, 1, 1),
        QuestRep(langID, themeID, 3, 1, 2),
        QuestRep(langID, themeID, 3, 1, 3),
     
      ];
    }
    if (niveau == "2") {
      data = [
        QuestRep(langID, themeID, 3, 0, 0),
        QuestRep(langID, themeID, 3, 0, 1),
        QuestRep(langID, themeID, 3, 0, 2),
        QuestRep(langID, themeID, 3, 0, 3),
        QuestRep(langID, themeID, 3, 0, 4),
        QuestRep(langID, themeID, 3, 0, 5),

        QuestRep(langID, themeID, 3, 1, 0),
        QuestRep(langID, themeID, 3, 1, 1),
        QuestRep(langID, themeID, 3, 1, 2),
        QuestRep(langID, themeID, 3, 1, 3),
        QuestRep(langID, themeID, 3, 1, 4),
        QuestRep(langID, themeID, 3, 1, 5),

        
      ];
    }
    return data;
  }
  function show_text_mapping2(niveau) {
    var data;
    if (niveau == "0") {
      data = [
        
        QuestRep(langID, themeID, 3, 0, 3),
        QuestRep(langID, themeID, 3, 0, 4),
        QuestRep(langID, themeID, 3, 0, 5),

        QuestRep(langID, themeID, 3, 1, 3),
        QuestRep(langID, themeID, 3, 1, 4),
        QuestRep(langID, themeID, 3, 1, 5),
        

      ];
    }
    if (niveau == "1") {
      data = [
        QuestRep(langID, themeID, 3, 0, 4),
        QuestRep(langID, themeID, 3, 0, 5),
        QuestRep(langID, themeID, 3, 0, 6),
        QuestRep(langID, themeID, 3, 0, 7),

        //0langID
        QuestRep(langID, themeID, 3, 1, 4),
        QuestRep(langID, themeID, 3, 1, 5),
        QuestRep(langID, themeID, 3, 1, 6),
      ];
    }
    if (niveau == "2") {
      data = [
        QuestRep(langID, themeID, 3, 0, 6),
        QuestRep(langID, themeID, 3, 0, 7),
        QuestRep(langID, themeID, 3, 0, 8),
        QuestRep(langID, themeID, 3, 0, 9),
        QuestRep(langID, themeID, 3, 0, 10),
        QuestRep(langID, themeID, 3, 0, 11),
        //0langID
        QuestRep(langID, themeID, 3, 1, 6),
        QuestRep(langID, themeID, 3, 1, 7),
        QuestRep(langID, themeID, 3, 1, 8),
        QuestRep(langID, themeID, 3, 1, 9),
        QuestRep(langID, themeID, 3, 1, 10),
        QuestRep(langID, themeID, 3, 1, 11),
      ];
    }
    return data;
  }
  //
  const [index, setIndex] = useState(1);
  function Seconde_Illustration0() {
  
    return (
      <>
        <div className="col  d-flex justify-content-center align-items-center">
          <img
            src="./img/Illustration-ville-niveau1.png"
            className="class-img"
            useMap="#image-map"
          />
        </div>
        <map name="image-map">
          <button style={{ border: "none" }} disabled={zone13} onClick={() => handleClickOpen(3, 0,4)}>
            <area
              target="_top"
              alt="jeter dans la poubelle"
              coords="25,303,3,399"
              shape="rect"
              
            />
          </button>
          
          <button style={{ border: "none" }} disabled={zone14}  onClick={() => handleClickOpen(4, 0,5)}>
            <area
              target="_top"
              alt="boire dans la gourde"
              coords="64,330,33,395"
              shape="rect"
             
            />
          </button>
          <button style={{ border: "none" }} disabled={zone15}  onClick={() => handleClickOpen(5, 0,6)}>
            {" "}
            <area
              target="_top"
              alt="rouler à vélo"
              coords=  "350,336,397,399"
              shape="rect"
            
            />
          </button>

          <button style={{ border: "none" }} disabled={zone16} onClick={() => handleClickOpenSeg(3, 0,4)}>
            <area
              target="_top"
              alt="nepas jeter dans la rue"
              coords= "285,235,220,276"
              shape="rect"
              
            />
          </button>
          <button style={{ border: "none" }} disabled={zone17} onClick={() => handleClickOpenSeg(4, 0,5)}>
            <area
              target="_top"
              alt="marchande en sac en sachet"
              coords="435,296,493,361"
              shape="rect"
           
            />
          </button>

          <button style={{ border: "none" }} disabled={zone18} onClick={() => handleClickOpenSeg(5, 0,6)}>
            <area
              target="_top"
              alt="voiture fumée"
              coords="107,308,211,364"
              shape="rect"
              
              // coords="10,365,76,443"
              // shape="rect"

              // onClick={() => handleClickOpen(7)}
            />
          </button>
        </map>
        <div className="col border" style={{marginLeft:40}}>
          <Dialog
            open={openSeg}
            onClose={handleClose}
            style={{
              position:'absolute',
              top: 25,
              marginTop: "10%",
              marginLeft:'-2%'
            }}
          >
            <DialogTitle
              style={{
                backgroundColor: "red",
               
                textAlign: "center",
                height:'6vw'
                
              }}
            >
             <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/no.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "#60523B",width: '27vw', height: '5vw',fontSize:'1.2vw' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: show_text_mapping2(themeID)[position1],
                  }}
                />
                {/* Les déchets organiques (restes des aliments, végétaux…) peuvent
                être donnés aux poules ou autre animal domestique. */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <img
                src="./img/cross.png"
                alt=""
                className=""
                onClick={handleClose}
                style={{
                  justifyContent: "center",
                  width: '4vw',
                  position: "absolute",
                  top: 4,
                }}
              />
            </DialogActions>
          </Dialog>

          <Dialog
            open={open}
            onClose={handleClose}
            style={{
              position: "absolute",
              top: 25,
              marginTop: "10%",
              marginLeft:'-2%'
            }}
          >
            <DialogTitle
              style={{
                backgroundColor: "#00884A",
                color: "#fff",
                textAlign: "center",
                height:'6vw'
             
              }}
            >
               <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/yes.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
              
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ width: '27vw', height: '5vw',fontSize:'1.2vw',color:"#60523B" }}>
                {}
                <div
                  dangerouslySetInnerHTML={{
                    __html: show_text_mapping(themeID)[position],
                  }}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <img
                src="./img/cross.png"
                alt=""
                className=""
                onClick={handleClose}
                style={{
                  justifyContent: "center",
                  width: '4vw',
                  position: "absolute",
                  top: 4,
                }}
              />
            </DialogActions>
          </Dialog>
        </div>
      </>
      //
    );
  }
  function Seconde_Illustration1() {
  
    return (
      <>
        <div className="col  d-flex justify-content-center align-items-center">
          <img
            src="./img/Illustration-ville-niveau2.png"
            className="class-img"
            useMap="#image-map"
          />
        </div>
        <map name="image-map">
          <button style={{ border: "none" }} disabled={zone13} onClick={() => handleClickOpen(4, 1,5)}>
            <area
              target="_top"
              alt="Planter les arbre"
              coords="346,224,317,264"
              shape="rect"
              
            />
          </button>
        
          <button style={{ border: "none" }} disabled={zone14}   onClick={() => handleClickOpen(5, 1,6)}>
            {" "}
            <area
              target="_top"
              alt="panneau"
              coords="32,311,0,399"
              shape="rect"
            
              // coords="551,242,493,290"
              // shape="rect"
              // onClick={() => handleClickOpenSeg(10)}
            />
          </button>

          <button style={{ border: "none" }} disabled={zone15}  onClick={() => handleClickOpen(6, 1,7)}>
            <area
              target="_top"
              alt="Lumière du jour"
              coords="60,331,36,392"
              shape="rect"
             
              // coords="510,425,569,449"
              // shape="rect"
              // onClick={() => handleClickOpenSeg(11)}
            />
          </button>
          <button style={{ border: "none" }} disabled={zone16}  onClick={() => handleClickOpen(7, 1,8)}>
            <area
              target="_top"
              alt="Climatiseur"
              coords="345,337,392,395"
              shape="rect"
             
            />
          </button>

          <button style={{ border: "none" }} disabled={zone17} onClick={() => handleClickOpenSeg(4, 1,5)}>
            <area
              target="_top"
              alt="femme aux cartons"
              coords="282,235,223,281"
              shape="rect"
              // coords="10,365,76,443"
              // shape="rect"

              // onClick={() => handleClickOpen(7)}
            />
          </button>
          <button style={{ border: "none" }} disabled={zone18} onClick={() => handleClickOpenSeg(5, 1,6)}>
            <area
              target="_top"
              alt="Homme à l'insecticide"
              coords="433,303,493,358"
              shape="rect"
              
              // coords="434,326,368,391"
              // shape="rect"

              // onClick={() => handleClickOpen(8)}
            />
          </button>

          <button style={{ border: "none" }} disabled={zone19} onClick={() => handleClickOpenSeg(6, 1,7)}>
            <area
              target="_top"
              alt="paillot"
              coords=  "207,316,108,372"
              shape="rect"
              
              // coords="258,382,286,433"
              // shape="rect"

              // onClick={() => handleClickOpen(11)}
            />
          </button>
        </map>
        <div>
          <Dialog
            open={openSeg}
            onClose={handleClose}
            style={{
              position: "absolute",
              top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
            }}
          >
            <DialogTitle
              style={{
                backgroundColor: "red",
                color: "#fff",
                textAlign: "center",
                height:'6vw'
                
              }}
            >
                <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/no.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "#60523B",width: '27vw', height: '5vw',fontSize:'1.2vw' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: show_text_mapping2(themeID)[position1],
                  }}
                />
                {/* Les déchets organiques (restes des aliments, végétaux…) peuvent
                être donnés aux poules ou autre animal domestique. */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <img
                src="./img/cross.png"
                alt=""
                className=""
                onClick={handleClose}
                style={{
                  justifyContent: "center",
                  width: '4vw',
                  position: "absolute",
                  top: 4,
                }}
              />
            </DialogActions>
          </Dialog>

          <Dialog
            open={open}
            onClose={handleClose}
            style={{
              position: "absolute",
              top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
            }}
          >
            <DialogTitle
              style={{
                backgroundColor: "#00884A",
                color: "#fff",
                textAlign: "center",
                height:'6vw'
                
              }}
            >
               <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/yes.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "#60523B",width: '27vw', height: '5vw',fontSize:'1.2vw' }}>
                {}
                <div
                  dangerouslySetInnerHTML={{
                    __html: show_text_mapping(themeID)[position],
                  }}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <img
                src="./img/cross.png"
                alt=""
                className=""
                onClick={handleClose}
                style={{
                  justifyContent: "center",
                  width: '4vw',
                  position: "absolute",
                  top: 4,
                }}
              />
            </DialogActions>
          </Dialog>
        </div>
      </>
      //
    );
  }
  function Seconde_Illustration() {
  
    return (
      <>
        <div className="col  d-flex justify-content-center align-items-center">
          <img
          src="./img/Illustration-ville-niveau3.png"
            className="class-img"
            useMap="#image-map"
          />
        </div>
        <map name="image-map">
          <button style={{ border: "none" }} disabled={zone13} onClick={() => handleClickOpen(6, 2,7)}>
            <area
              target="_top"
              alt="Planter les arbre"
              coords="353,214,315,264"
              shape="rect"
              
            />
          </button>
          <button style={{ border: "none" }} disabled={zone14} onClick={() => handleClickOpen(7, 2,8)} >
            <area
              target="_top"
              alt="poubelle"
              coords="29,312,0,390"
              shape="rect"
             
            />
          </button>

          <button style={{ border: "none" }} disabled={zone15} onClick={() => handleClickOpen(8, 2,9)} >
            <area
              target="_top"
              alt="jardin"
              coords="296,302,357,350"
              shape="rect"
              
            />
          </button>
          <button style={{ border: "none" }} disabled={zone16} onClick={() => handleClickOpen(9, 2,10)}>
            <area
              target="_top"
              alt="recueillir de l'eau"
              coords="63,331,34,398"
              shape="rect"
              
            />
          </button>
          <button style={{ border: "none" }} disabled={zone17} onClick={() => handleClickOpen(10, 2,11)}>
            {" "}
            <area
              target="_top"
              alt="panneau"
              coords="401,338,364,399"
              shape="rect"
            
            />
          </button>

          <button style={{ border: "none" }} disabled={zone18} onClick={() => handleClickOpen(11, 2,12)}>
            <area
              target="_top"
              alt="Lumière du jour"
              coords="221,327,176,390,446,292,281,233"
              shape="rect"
          
            />
          </button>
          <button style={{ border: "none" }} disabled={zone19} onClick={() => handleClickOpenSeg(6, 2,7)} >
            <area
              target="_top"
              alt="Climatiseur"
              coords="291,230,230,275"
              shape="rect"
              
            />
          </button>

          <button style={{ border: "none" }}  disabled={zone20} onClick={() => handleClickOpenSeg(7, 2,8)}>
            <area
              target="_top"
              alt="femme aux cartons"
              coords="496,302,451,363"
              shape="rect"
             
            />
          </button>
          <button style={{ border: "none" }}  disabled={zone21} onClick={() => handleClickOpenSeg(8, 2,9)}>
            <area
              target="_top"
              alt="Homme à l'insecticide"
              coords= "522,309,635,372"
              shape="rect"
              
            />
          </button>
          <button style={{ border: "none" }}  disabled={zone22} onClick={() => handleClickOpenSeg(9, 2,10)}>
            <area
              target="_top"
              alt="une personne qui jette de l'eau"
              coords="98,310,168,371"
              shape="rect"
              
            />
          </button>
          <button style={{ border: "none" }}  disabled={zone23} onClick={() => handleClickOpenSeg(10, 2,11)}>
            <area
              target="_top"
              alt="Poubelle au coin"
              coords="472,220,408,264,480,223"
              shape="rect"
              
            />
          </button>

          <button style={{ border: "none" }}  disabled={zone24} onClick={() => handleClickOpenSeg(11, 2,12)}>
            <area
              target="_top"
              alt="paillot"
              coords="484,376,424,399,425,395,424,399"
              shape="rect"
            />
          </button>
        </map>
        <div>
          <Dialog
            open={openSeg}
            onClose={handleClose}
            style={{
              position: "absolute",
              top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
            }}
          >
            <DialogTitle className="body-text"
              style={{
                backgroundColor: "red",
                color: "#fff",
                textAlign:'center',
                height:'6vw'
              }}
            >
             <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/no.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "#60523B",width: '27vw', height: '5vw',fontSize:'1.2vw' }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: show_text_mapping2(themeID)[position1],
                  }}
                />
                {/* Les déchets organiques (restes des aliments, végétaux…) peuvent
                être donnés aux poules ou autre animal domestique. */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <img
                src="./img/cross.png"
                alt=""
                className=""
                onClick={handleClose}
                style={{
                  justifyContent: "center",
                  width:'4vw',
                  position: "absolute",
                  top: 4,
                }}
              />
            </DialogActions>
          </Dialog>

          <Dialog
            open={open}
            onClose={handleClose}
            style={{
              position: "absolute",
              top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
            }}
          >
            <DialogTitle
              style={{
                backgroundColor: "#00884A",
                color: "#fff",
                fontWeight: "bold",
                textAlign: "center",
                height:'6vw'
                
              }}
            >
               <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/yes.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "#60523B",width: '27vw', height: '5vw',fontSize:'1.2vw' }}>
                {}
                <div
                  dangerouslySetInnerHTML={{
                    __html: show_text_mapping(themeID)[position],
                  }}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <img
                src="./img/cross.png"
                alt=""
                className=""
                onClick={handleClose}
                style={{
                  justifyContent: "center",
                  width:'4vw',
                  position: "absolute",
                  top: 4,
                }}
              />
            </DialogActions>
          </Dialog>
        </div>
      </>
      //
    );
  }
  function Change_Img(numero,niv) {
    if(niv =='0'){
      if (numero == 1) {
        return (
          <>
            <div className="col  d-flex justify-content-center align-items-center">
              <img
                src='./img/Illustration-ecologie-niveau1.png'
                alt="Workplace"
                useMap="#workmap"
              />
            </div>
            <map name="workmap">
              <button disabled={zone1} style={{ border: "none" }} onClick={() => handleClickOpen(0, 0,1)}>
                <area
                  target="_top"
                  alt="Femme aux poules"
                  coords="547,208,618,336"
                  shape="rect"
                  
                />
              </button>
              <button disabled={zone2} style={{ border: "none" }} onClick={() => handleClickOpen(1, 0,2)}>
                <area
                  target="_top"
                  alt="Eplucher des bananes"
                  coords="84,193,43,312"
                  shape="rect"
                  
                />
              </button>
  
              <button disabled={zone3} style={{ border: "none" }} onClick={() => handleClickOpen(2, 0,3)}>
                <area
                  target="_top"
                  alt="jardin"
                  coords="193,322,30,399"
                  shape="rect"
                  
                />
              </button>
              
  
              <button style={{ border: "none" }} disabled={zone4} onClick={() => handleClickOpenSeg(0, 0,1)}>
                <area
                  target="_top"
                  alt="Lumière du jour"
                  coords= "25,55,76,152"
                  shape="rect"
                  
                />
              </button>
              <button style={{ border: "none" }} disabled={zone5} onClick={() => handleClickOpenSeg(1, 0,2)}>
                <area
                  target="_top"
                  alt="une personne qui jette de l'eau"
                  coords="478,308,406,395"
                  shape="rect"
                  
                />
              </button>
              <button style={{ border: "none" }} disabled={zone6} onClick={() => handleClickOpenSeg(2, 0,3)}>
                <area
                  target="_top"
                  alt="Poubelle au coin"
                  coords="593,343,695,399"
                  shape="rect"
                  
                />
              </button>
            </map>
            <div>
              <Dialog
                open={openSeg}
                onClose={handleClose}
                style={{
                  position: "absolute",
                  top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
                }}
              >
                <DialogTitle className="body-text"
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    textAlign: "center",
                    height:'6vw'
                  
                  }}
                >
                  <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/no.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ width: '27vw', height: '5vw',fontSize:'1.2vw',color:"#60523B" }}>
                    <div style={{color:"#60523B"}}
                      dangerouslySetInnerHTML={{
                        __html: show_text_mapping2(themeID)[position1],
                      }}
                    />
                    {/* Les déchets organiques (restes des aliments, végétaux…) peuvent
            être donnés aux poules ou autre animal domestique. */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <img
                    src="./img/cross.png"
                    alt=""
                    className=""
                    onClick={handleClose}
                    style={{
                      justifyContent: "center",
                      width: "4vw",
                      position: "absolute",
                      top: 4,
                    }}
                  />
                </DialogActions>
              </Dialog>
  
              <Dialog
                open={open}
                onClose={handleClose}
                style={{
                  position: "absolute",
                  top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
                }}
              >
                <DialogTitle
                  style={{
                    backgroundColor: "#00884A",
                    color: "#fff",
                    textAlign: "center",height:"6vw"
                  
                  }}
                >
                   <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/yes.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ width: '27vw', height: '5vw',fontSize:'1.2vw',color:"#60523B" }}>
                    {}
                    <div style={{color:"#60523B"}}
                      dangerouslySetInnerHTML={{
                        __html: show_text_mapping(themeID)[position],
                      }}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <img
                    src="./img/cross.png"
                    alt=""
                    className=""
                    onClick={handleClose}
                    style={{
                      justifyContent: "center",
                      width: "4vw",
                      position: "absolute",
                      top: 4,
                    }}
                  />
                </DialogActions>
              </Dialog>
            </div>
          </>
        );
      }
      if (numero == 2) {
        return <Seconde_Illustration0 />;
      }

    }if(niv =='1'){
      if (numero == 1) {
        return (
          <>
            <div className="col  d-flex justify-content-center align-items-center">
              <img
           src='./img/Illustration-ecologie-niveau2.png'
                alt="Workplace"
                useMap="#workmap"
              />
            </div>
            <map name="workmap">
              <button style={{ border: "none" }} disabled={zone1}  onClick={() => handleClickOpen(0, 1,1)}>
                <area
                  target="_top"
                  alt="Femme aux poules"
                  coords="553,209,595,333"
                  shape="rect"
                 
                />
              </button>
              <button style={{ border: "none" }} disabled={zone2} onClick={() => handleClickOpen(1, 1,2)}>
                <area
                  target="_top"
                  alt="Eplucher des bananes"
                  coords= "36,193,76,310"
                  shape="rect"
                  
                />
              </button>
  
              <button style={{ border: "none" }} disabled={zone3} onClick={() => handleClickOpen(2, 1,3)}>
                <area
                  target="_top"
                  alt="jardin"
                  coords="6,320,190,395"
                  shape="rect"
                  
                />
              </button>
              
              <button style={{ border: "none" }} disabled={zone4} onClick={() => handleClickOpen(3, 1,4)}>
                {" "}
                <area
                  target="_top"
                  alt="panneau"
                  coords="424,5,308,66"
                  shape="rect"
                  
                />
              </button>
  
              <button style={{ border: "none" }} disabled={zone5} onClick={() => handleClickOpenSeg(0, 1,1)}>
                <area
                  target="_top"
                  alt="Lumière du jour"
                  coords= "28,44,74,131"
                  shape="rect"
                  
                />
              </button>
              <button style={{ border: "none" }} disabled={zone6} onClick={() => handleClickOpenSeg(1, 1,2)}>
                <area
                  target="_top"
                  alt="Climatiseur"
                  coords="401,101,448,173"
                  shape="rect"
                 
                />
              </button>
              <button style={{ border: "none" }} disabled={zone7}  onClick={() => handleClickOpenSeg(2, 1,3)}>
                <area
                  target="_top"
                  alt="une personne qui jette de l'eau"
                  coords="528,300,463,387"
                  shape="rect"
                 
                />
              </button>
              <button style={{ border: "none" }} disabled={zone8}  onClick={() => handleClickOpenSeg(3, 1,4)}>
                <area
                  target="_top"
                  alt="Poubelle au coin"
                  coords="597,340,695,396"
                  shape="rect"
                 
                />
              </button>
            </map>
            <div >
              <Dialog
                open={openSeg}
                onClose={handleClose}
                style={{
                  position: "absolute",
                  top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
                }}
              >
                <DialogTitle className="body-text"
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    textAlign: "center",height:'6vw'
                  
                  }}
                >
                   <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/no.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ width: '27vw', height: '5vw',fontSize:'1.2vw',color:"#60523B" }}>
                    <div style={{color:"#60523B"}}
                      dangerouslySetInnerHTML={{
                        __html: show_text_mapping2(themeID)[position1],
                      }}
                    />
                    {/* Les déchets organiques (restes des aliments, végétaux…) peuvent
            être donnés aux poules ou autre animal domestique. */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <img
                    src="./img/cross.png"
                    alt=""
                    className=""
                    onClick={handleClose}
                    style={{
                      justifyContent: "center",
                      width: '4vw',
                      position: "absolute",
                      top: 4,
                    }}
                  />
                </DialogActions>
              </Dialog>
  
              <Dialog
                open={open}
                onClose={handleClose}
                style={{
                  position: "absolute",
                  top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
                }}
              >
                <DialogTitle className="body-text"
                  style={{
                    backgroundColor: "#00884A",
                    color: "#fff",
                    textAlign: "center",
                  height:'6vw'
                  }}
                >
                   <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/yes.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ width: '27vw', height: '5vw',fontSize:'1.2vw',color:"#60523B" }}>
                    {}
                    <div style={{color:"#60523B"}}
                      dangerouslySetInnerHTML={{
                        __html: show_text_mapping(themeID)[position],
                      }}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <img
                    src="./img/cross.png"
                    alt=""
                    className=""
                    onClick={handleClose}
                    style={{
                      justifyContent: "center",
                      width: '4vw',
                      position: "absolute",
                      top: 4,
                    }}
                  />
                </DialogActions>
              </Dialog>
            </div>
          </>
        );
      }
      if (numero == 2) {
        return <Seconde_Illustration1 />;
      }

    }if(niv =='2'){
      if (numero == 1) {
        return (
          <>
            <div className="col  d-flex justify-content-center align-items-center">
              <img
             src='./img/Illustration-ecologie-niveau3.png'
                alt="Workplace"
                useMap="#workmap"
              />
            </div>
            <map name="workmap">
              <button style={{ border: "none" }} disabled={zone1} onClick={() => handleClickOpen(0, 2,1)}>
                <area
                  target="_top"
                  alt="Femme aux poules"
                  coords={Coordonnees(1, 2, 0)}
                  shape="rect"
                />
              </button>
              <button style={{ border: "none" }} disabled={zone2} onClick={() => handleClickOpen(1, 2,2)}>
                <area
                  target="_top"
                  alt="Eplucher des bananes"
                  coords={Coordonnees(1, 2, 1)}
                  shape="rect"
                  
                />
              </button>
              <button style={{ border: "none" }} disabled={zone3} onClick={() => handleClickOpen(2, 2,3)}>
                <area
                  target="_top"
                  alt="paillot"
                  coords={Coordonnees(1, 2, 2)}
                  shape="rect"
                  
                />
              </button>
  
              <button style={{ border: "none" }} disabled={zone4} onClick={() => handleClickOpen(3, 2,4)}>
                <area
                  target="_top"
                  alt="jardin"
                  coords={Coordonnees(1, 2, 3)}
                  shape="rect"
                  
                />
              </button>
              <button style={{ border: "none" }} disabled={zone5} onClick={() => handleClickOpen(4, 2,5)}>
                <area
                  target="_top"
                  alt="recueillir de l'eau"
                  coords={Coordonnees(1, 2, 4)}
                  shape="rect"
                  
                />
              </button>
              <button style={{ border: "none" }} disabled={zone6}  onClick={() => handleClickOpen(5, 2,6)}>
                {" "}
                <area
                  target="_top"
                  alt="panneau"
                  coords={Coordonnees(1, 2, 5)}
                  shape="rect"
                 
                />
              </button>
  
              <button style={{ border: "none" }} disabled={zone7} onClick={() => handleClickOpenSeg(0, 2,1)}>
                <area
                  target="_top"
                  alt="Lumière du jour"
                  coords={Coordonnees(1, 2, 6)}
                  shape="rect"
                  
                />
              </button>
              <button style={{ border: "none" }} disabled={zone8}   onClick={() => handleClickOpenSeg(1, 2,2)}>
                <area
                  target="_top"
                  alt="Climatiseur"
                  coords={Coordonnees(1, 2, 7)}
                  shape="rect"
                
                  // coords="444,119,487,184"
                  // shape="rect"
                  // onClick={() => handleClickOpenSeg(1)}
                />
              </button>
  
              <button style={{ border: "none" }} disabled={zone9}  onClick={() => handleClickOpenSeg(2, 2,3)}>
                <area
                  target="_top"
                  alt="femme aux cartons"
                  coords={Coordonnees(1, 2, 8)}
                  shape="rect"
                 
                  // coords="209,244,244,368"
                  // shape="rect"
                  // onClick={() => handleClickOpenSeg(2)}
                />
              </button>
              <button style={{ border: "none" }} disabled={zone10} onClick={() => handleClickOpenSeg(3, 2,4)}>
                <area
                  target="_top"
                  alt="Homme à l'insecticide"
                  coords={Coordonnees(1, 2, 9)}
                  shape="rect"
                  
                  // coords="388,324,337,419"
                  // shape="rect"
                  // onClick={() => handleClickOpenSeg(3)}
                />
              </button>
              <button style={{ border: "none" }} disabled={zone11}  onClick={() => handleClickOpenSeg(4, 2,5)}>
                <area
                  target="_top"
                  alt="une personne qui jette de l'eau"
                  coords={Coordonnees(1, 2, 10)}
                  shape="rect"
                 
                />
              </button>
              <button style={{ border: "none" }} disabled={zone12}  onClick={() => handleClickOpenSeg(5, 2,6)}>
                <area
                  target="_top"
                  alt="Poubelle au coin"
                  coords={Coordonnees(1, 2, 11)}
                  shape="rect"
                 
                />
              </button>
            </map>
            <div>
              <Dialog
                open={openSeg}
                onClose={handleClose}
                style={{
                  position: "absolute",
                  top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
                }}
              >
                <DialogTitle
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    fontWeight: "bold",
                    textAlign:'center',
                    height:'6vw'
                  }}
                >
                 <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/no.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ width: '27vw', height: '5vw',fontSize:'1.2vw',color:"#60523B" }}>
                    <div style={{color:"#60523B"}}
                      dangerouslySetInnerHTML={{
                        __html: show_text_mapping2(themeID)[position1],
                      }}
                    />
                    {/* Les déchets organiques (restes des aliments, végétaux…) peuvent
            être donnés aux poules ou autre animal domestique. */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <img
                    src="./img/cross.png"
                    alt=""
                    className=""
                    onClick={handleClose}
                    style={{
                      justifyContent: "center",
                      width: '4vw',
                      position: "absolute",
                      top: 4,
                    }}
                  />
                </DialogActions>
              </Dialog>
  
              <Dialog
                open={open}
                onClose={handleClose}
                style={{
                  position: "absolute",
                  top: 25,
                  marginTop: "10%",
                  marginLeft:'-2%'
                }}
              >
                <DialogTitle
                  style={{
                    backgroundColor: "#00884A",
                    color: "#fff",
                    fontWeight: "bold",
                    height:'6vw'
                  }}
                >
                 <p style={{ color: "#fff",fontSize:'1.5vw',textAlign:'center'}}>
              <img alt=""
              src='./img/yes.png'
              style={{width:'4vw',marginTop: "-3%",}}
              />
              </p>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ width: '27vw', height: '5vw',fontSize:'1.2vw',color:"#60523B" }}>
                    {}
                    <div style={{color:"#60523B"}}
                      dangerouslySetInnerHTML={{
                        __html: show_text_mapping(themeID)[position],
                      }}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <img
                    src="./img/cross.png"
                    alt=""
                    className=""
                    onClick={handleClose}
                    style={{
                      justifyContent: "center",
                      width: '4vw',
                      position: "absolute",
                      top: 4,
                    }}
                  />
                </DialogActions>
              </Dialog>
            </div>
          </>
        );
      }
      if (numero == 2) {
        return <Seconde_Illustration />;
      }
    }

    
  }
  //

  function Coordonnees(numero, niveau, index) {
    var val;

    if (niveau == "0") {
      if (numero === 1) {
        val = [
          //femmes aux poules
          "654,369,605,237",
          //epulcher
          "96,348,50,226",
          //jardin
          "27,363,209,446",
          //eclairage
          "28,52,93,171",
          //jeter de l'eau
          "459,336,540,436",
          //saleté au coin
          "653,385,777,448",
        ];
      }
      if (numero === 2) {
        val = [
          //jeter publle
          "73,365,5,442",
          //boire dans le gourde
          "116,375,90,436",
          //rouler à vélo
          "449,373,490,447",
          //ne pas jeter dans rues
          "369,266,316,304",
          //la marcjhande au sac
          "617,338,548,409",
          //voitiure avec la fumée
          "279,346,187,412",
        ];
      }
    }
    if (niveau == "1") {
      if (numero == 1) {
        val = [
          //femmes aux poules
          "611,238,657,371",
          //épulcher les déchets
          "92,352,49,222",
          //jardin
          "13,369,214,442",
          //Panneaux
          "486,11,336,79",
          //Eclairage
          "21,61,90,177",
          //climatiseur
          "454,109,493,190",
          //jeter de l'eau
          "601,432,516,349",
          //déchet au coin
          "670,383,775,449",
        ];
      }
      if (numero === 2) {
        val = [
          //planter des arbres
          "454,247,423,288",
          //poubelle
          "74,366,6,443",
          //gourde
          "134,368,92,438",
          //rouvel à vélo
          "461,371,503,446",
          //jeter les déchet dans la rue
          "326,261,373,304",
          //marchande aux sacs
          "565,334,619,407",
          //voiture à la fumée
          "181,344,301,411",
        ];
      }
    }
    if (niveau == "2") {
      if (numero === 1) {
        val = [
          //femmes aux poules
          "511,208,561,320",
          //epulcher
          "7,197,56,305",
          //materiaux renouvelables
          "449,169,256,269",
          //jardin
          "159,331,0,392",
          //récueillir de l'eau
          "121,291,89,91,108,90",
          //panneaux
          "396,8,265,72",

          //eclairage
          "46,53,0,164",
          //climatiseur
          "366,100,409,163",
          //femmes aux carton
          "206,324,160,208",
          //insecticide
          "255,280,325,365",
          //jeter de l'eau
          "421,299,500,369",
          //déchet au coin
          "650,332,558,399",
        ];
      }
      if (numero ===2) {
        val = [
          //planter les arbres
          "393,249,435,293",
          //jeter dans la poubelle
          "77,357,9,442",
          //taxi avec +++ personnes
          "433,333,371,403",
          //boire avec sa gourde
          "138,367,89,443",
          //rouler à velo
          "493,384,429,448",
          //porter sac ou cartable
          "256,371,299,439",

          //jetr les déchet dans la rue
          "358,263,304,299",
          //marchande aux sacs
          "530,338,579,402",
          //rouler seul dans une grosse voiture
          "740,420,598,347",
          //voiture à fumée
          "249,351,164,412",
          //déchet dans la rivière
          "493,241,564,289",
          //canalisation
          "585,414,504,447",
        ];
      }
    }
    return val[index];
  }

  //States changement couleurs
  const [couleur1, setCouleur1] = useState("white");

  var style1 = {
    backgroundColor: couleur1,
  };
  const [couleur2, setCouleur2] = useState("white");

  var style2 = {
    backgroundColor: couleur2,
  };
  const [couleur3, setCouleur3] = useState("white");

  var style3 = {
    backgroundColor: couleur3,
  };
  const [couleur4, setCouleur4] = useState("white");

  var style4 = {
    backgroundColor: couleur4,
  };
  const [couleur5, setCouleur5] = useState("white");

  var style5 = {
    backgroundColor: couleur5,
  };
  const [couleur6, setCouleur6] = useState("white");

  var style6 = {
    backgroundColor: couleur6,
  };
  const [couleur7, setCouleur7] = useState("white");

  var style7 = {
    backgroundColor: couleur7,
  };
  const [couleur8, setCouleur8] = useState("white");

  var style8 = {
    backgroundColor: couleur8,
  };
  const [couleur9, setCouleur9] = useState("white");

  var style9 = {
    backgroundColor: couleur9,
  };
  const [couleur10, setCouleur10] = useState("white");

  var style10 = {
    backgroundColor: couleur10,
  };
  const [couleur11, setCouleur11] = useState("white");

  var style11 = {
    backgroundColor: couleur11,
  };
  const [couleur12, setCouleur12] = useState("white");

  var style12 = {
    backgroundColor: couleur12,
  };
  //
  const [show, setShow] = useState(false);
  const fermer = () => setShow(false);
  const ouvrir = () => setShow(true);

  //Function du compteur

  function show_niveau_compteur(niveau) {
    if (niveau == "0") {
      return (
        <>
          <div
            className="row cases-point1"
            style={{ margin:"auto"}}
          >
            <div className="col case" id="1" style={style1}>
              <p>
                <br />
              </p>
            </div>
            <div className="col case" id="2" style={style2}></div>
            <div className="col case " id="3" style={style3}></div>
            <div className="col case" id="4" style={style4}></div>
            <div className="col case" id="5" style={style5}></div>
            <div className="col case" id="6" style={style6}></div>
          </div>
        </>
      );
    }
    if (niveau == "1") {
      return (
        <>
          <div
            className="row cases-pointMod2"
            style={{ margin:'auto'}}
          >
            <div className="col case" id="1" style={style1}>
              <p>
                <br />
              </p>
            </div>
            <div className="col case" id="2" style={style2}></div>
            <div className="col case " id="3" style={style3}></div>
            <div className="col case" id="4" style={style4}></div>
            <div className="col case" id="5" style={style5}></div>
            <div className="col case" id="6" style={style6}></div>
            <div className="col case" id="7" style={style7}></div>
            <div className="col case" id="8" style={style8}></div>
          </div>
        </>
      );
    }
    if (niveau == "2") {
      return (
        <>
          <div
            className="row cases-point2"
            style={{ margin:'auto'}}
          >
            <div className="col case" id="1" style={style1}>
              <p>
                <br />
              </p>
            </div>
            <div className="col case" id="2" style={style2}></div>
            <div className="col case " id="3" style={style3}></div>
            <div className="col case" id="4" style={style4}></div>
            <div className="col case" id="5" style={style5}></div>
            <div className="col case" id="6" style={style6}></div>
            <div className="col case" id="7" style={style7}></div>
            <div className="col case" id="8" style={style8}></div>
            <div className="col case" id="9" style={style9}></div>
            <div className="col case" id="10" style={style10}></div>
            <div className="col case" id="11" style={style11}></div>
            <div className="col case" id="12" style={style12}></div>
          </div>
        </>
      );
    }
  }
//
const [zone1,setZone1] = useState(false)
const [zone2,setZone2] = useState(false)
const [zone3,setZone3] = useState(false)
const [zone4,setZone4] = useState(false)
const [zone5,setZone5] = useState(false)
const [zone6,setZone6] = useState(false)
const [zone7,setZone7] = useState(false)
const [zone8,setZone8] = useState(false)
const [zone9,setZone9] = useState(false)
const [zone10,setZone10] = useState(false)
const [zone11,setZone11] = useState(false)
const [zone12,setZone12] = useState(false)

const [zone13,setZone13] = useState(false)
const [zone14,setZone14] = useState(false)
const [zone15,setZone15] = useState(false)
const [zone16,setZone16] = useState(false)
const [zone17,setZone17] = useState(false)
const [zone18,setZone18] = useState(false)

const [zone19,setZone19] = useState(false)
const [zone20,setZone20] = useState(false)
const [zone21,setZone21] = useState(false)
const [zone22,setZone22] = useState(false)
const [zone23,setZone23] = useState(false)
const [zone24,setZone24] = useState(false)
//

  return (
    <div className="container-fluid">
      <div className="col" style={{marginTop:'-5%'}}>
        <div
          className="row mt-4 "
          style={{
            fontSize: "2vw",
            fontWeight: "bold",
            color: "#00884A",
            textTransform: "uppercase", 
          }}
        >
          <div className="col-1 "  style={{marginTop:'5%',position:'absolute'}}>
            <div className="accueil">
              <Accueil />
            </div>
          </div>
          <div
            className="col"
            style={{ textAlign: "center",marginTop:'4%',marginLeft:'8%' }}
            dangerouslySetInnerHTML={{
              __html: Titre_Module(langID, themeID, 3),
            }}
          />
          <div className="col-1"></div>
        </div>
        <Modal show={show} onHide={fermer} size="lg" style={{ width: "100%" }}>
          <div className="row" id="finpartie">
            <div className="row finpartie-text" style={{ marginBottom: "-1%" }}>
              <p className="h2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: FIN_DE_PAGE(langID, 0, 4),
                  }}
                />
              </p>
            </div>
            <div className="col mb-5 mt-5">
              <div className="col">{show_niveau_compteur(themeID)}</div>
            </div>
            <div
              className="row finpartie-btn-groupe mb-5"
              style={{ marginTop: "-2%" }}
            >
              <div className="col center finpartie-btn">
                <NavLink to="" onClick={() => window.location.reload()}>
                  <img
                    src="./img/bouton_retour.png"
                    alt="Récommencer"
                    className=""
                    style={{ width: "5vw", marginLeft: "80%" }}
                  />
                </NavLink>
              </div>
              <div className="col center finpartie-btn">
                <Accueil />
              </div>
            </div>
          </div>
        </Modal>
        <div>{show_niveau_compteur(themeID)}</div>
        <div className="row centrer">
          <div className="col">
          <div
         className="row"
         style={{
          margin: "auto",
          backgroundColor: "#00884A",
          height: "4vw",
          marginTop: "1vw",
         }}
       >
        <div className="col-3"></div>
         <div className="col pt-2" >
           <p
             className=" col h4 texte-choix-reponse centrer"
             style={{  color: "#ffff",textAlign:'center' }}
           >
             <div
               className="col body-text"
               dangerouslySetInnerHTML={{
                 __html: Barre_titre(langID, themeID, 0, 3),
               }}
             />
           </p>
         </div>
         <div
           className=" col-1  "
           style={{ width: "1%"}}
         >
           <div className="col ">
             <button
               onClick={() =>
                 preloadAudio("musc1", Audios(langID, themeID, 3, 0))
               }
               id="preload1"
               style={{ display: "none" }}
             >
               Preload
             </button>
           </div>
           <div className="col width-barre-titre">
             <input
               type="image"
               onClick={() =>
                 handlePlay(
                   "musc1",

                   "preload1"
                 )
               }
               id="btn-play1"
               src="./img/bouton-son.png"
               style={{
                 width: "4vw",
               }}
             />
           </div>
         </div>
         <div className="col-3"></div>
       </div>
       <div className="col-2"></div>
            <div
              className="row" style={{marginTop:'2%'}}
            >
                 {Change_Img(index, themeID)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Module4