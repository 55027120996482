import React, { useState } from "react";
import Logo from "./components/logo";
import Parametre from "./components/setting";
import { NavLink } from "react-router-dom";
//import du fichier json
import LANG from "./backOffice/properties.json";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import { NativeAudio } from "@capacitor-community/native-audio";
import $ from 'jquery';

//Langue par défaut Français
if (sessionStorage.getItem("Lang") === null) {
  sessionStorage.setItem("Lang", 0);
}
if (sessionStorage.getItem("theme") === null) {
      sessionStorage.setItem("theme", 0);
    }
const Main = () => {
  sessionStorage.setItem("onglet", "one");

  const [isHovering, setIsHovering] = useState(false);
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  //
  useEffect(() => {
    if (sessionStorage.getItem("theme") === null) {
      sessionStorage.setItem("theme", 0);
    }
  }, []);

  const itemBox = $('.clicked');
  itemBox.click(function(){
    $('.clicked').each(function(i, elem){
      $(elem).removeClass('click-focused')
    })
    $(this).addClass('click-focused');
  })
  //
  function Choix(nbre) {
    sessionStorage.setItem("theme", nbre);
    if (nbre === 3) {
      sessionStorage.setItem("diversPage", "none");
    } else {
      sessionStorage.setItem("diversPage", "");
    }

    console.log(
      "niveau changé :" + sessionStorage.getItem("theme"),
      sessionStorage.getItem("diversPage")
    );
  }

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleMouseOver1 = () => {
    setIsHovering1(true);
  };
  const handleMouseOut1 = () => {
    setIsHovering1(false);
  };
  const handleMouseOver2 = () => {
    setIsHovering2(true);
  };
  const handleMouseOut2 = () => {
    setIsHovering2(false);
  };
  const handleMouseOver3 = () => {
    setIsHovering3(true);
  };
  const handleMouseOut3 = () => {
    setIsHovering3(false);
  };

  //Titre du module
  function Titre_Module(IdLang, IdMod, seq) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[IdMod].sequences[seq]
        .title;
    });
    return data;
  }
  //niveau
  function Levels(IdLang, page) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[4].pages[
        page
      ].title;
    });
    return data;
  }
  function Activites(IdLang, page) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[4].pages[
        page
      ].title;
    });
    return data;
  }
  function stopBtn() {
    NativeAudio.unload({ assetId: "musc1" });
    console.log("btn stop cliqué");
  }

  return (
  stopBtn(),
  console.log("langue selectionnée : " + sessionStorage.getItem("Lang")),
  (  <div className="container-fluid">
    
      <div className="row ">
          <div className="row">
            <div className="col ">
              <img src="./img/logo.png" alt="" className="logo-appli" />
            </div>
            <div className="col marge-haut" style={{margin:'auto',marginTop:'2%'}} >
              <Parametre />
            </div>
          </div>

          <div className="row " style={{marginTop:'-3.5%',marginBottom:'2%'}} >
            <div className="col " >
              <div class="col" >
                <div className="container" style={{}}>
                  <p className="col pt-1 activite2" >
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: Levels(sessionStorage.getItem("Lang"), 1),
                      }}
                    />
                  </p>
                </div>
                <div className="card-niveaux">
                  <div className="row down-niv">
                    <button className="col-4 niv-1 click clicked" onClick={() => Choix(0)}>
                    

                      <img
                        src="./img/niveau-1-min.png"
                        alt=""
                        className=""
                        style={{ width:"5vw" }}
                      />
                    </button>
                    <div className="col ">
                      <button className="col-sm-2 niv-2 click clicked" onClick={() => Choix(1)}>
                        <img
                          src="./img/niveau-2-min.png"
                          alt=""
                          className=""
                          style={{ width:"5vw" }}
                        />
                      </button>
                    </div>
                    <div className="col  ">
                      <button className="col-sm-2 niv-3 click clicked" onClick={() => Choix(2)}>
                        <img
                          src="./img/niveau-3-min.png"
                          alt=""
                          className=" "
                          style={{ width:"5vw" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col " style={{}}>
              <div className="col">
                <div className=" container2">
                  <p className="col pt-1 activite2">
                  <div
                      dangerouslySetInnerHTML={{
                        __html: Activites(sessionStorage.getItem("Lang"), 2),
                      }}
                    />
                  </p>

                  
                </div>
              </div>
              <div className="row column">
      <div className="col-2"></div>
        <div className="col">
        <NavLink to='/module1'>
        <div className="col column-btn" style={{marginBottom:'20%'}}>
        <div className="col img-yello-absolute">
                            <div className=" col ">
                              <img
                                src="./img/objet-titre.png"
                                alt=""
                                className="img-yellow"
                                
                              />
                            </div>
                            
                            <div class="col text-img-yellow">
        
                            <p className="col pt-2 " 
                            dangerouslySetInnerHTML={{
                                  __html: Titre_Module(
                                    sessionStorage.getItem("Lang"),
                                    sessionStorage.getItem("theme"),
                                    0
                                  ),
                                }}/> 
                             
                            </div>
                          
                          </div>
                          <div className="img-play">
                          <img
                            src="./img/icone-play.png"
                            alt=""
                            className=""
                            style={{  width: '2vw' }}
                          />
                        </div>
        <img
                            src="./img/icone-eco-citoyen1.png"
                            alt=""
                            className="img-act "
                            style={{
                              width: '10vw',
                              marginLeft: '-15%',
                              marginTop:'10%'
                             
                            }}
                          />
        </div>
        </NavLink>
        
        <NavLink to='/module3'>
        <div className="col column-btn">
        <div className="col img-yello-absolute">
                            <div className=" col ">
                              <img
                                src="./img/objet-titre.png"
                                alt=""
                                className="img-yellow"
                                
                              />
                            </div>
                            
                            <div class="col text-img-yellow">
                            <p className="col pt-2 " 
                            dangerouslySetInnerHTML={{
                                  __html: Titre_Module(
                                    sessionStorage.getItem("Lang"),
                                    sessionStorage.getItem("theme"),
                                    2
                                  ),
                                }}/> 
                             
                            </div>
                          
                          </div>
                          <div className="img-play">
                          <img
                            src="./img/icone-play.png"
                            alt=""
                            className=""
                            style={{  width: '2vw' }}
                          />
                        </div>
        <img
                            src="./img/icone-eco-citoyen2.png"
                            alt=""
                            className="img-act"
                            style={{
                              width: '10vw',
                              marginLeft: '-15%',
                              marginTop:'10%'
                             
                            }}
                          />
        </div>
        </NavLink>
        
        </div>

        <div className="col">
        <NavLink to='/module2'>
        <div className="col column-btn-two" style={{marginBottom:'20%'}}>
        <div className="col img-yello-absolute-two">
                            <div className=" col ">
                              <img
                                src="./img/objet-titre.png"
                                alt=""
                                className="img-yellow"
                                
                              />
                            </div>
                            
                            <div class="col text-img-yellow">
                            <p className="col pt-2 " 
                            dangerouslySetInnerHTML={{
                                  __html: Titre_Module(
                                    sessionStorage.getItem("Lang"),
                                    sessionStorage.getItem("theme"),
                                    1
                                  ),
                                }}/> 
                             
                            </div>
                          
                          </div>
                          <div className="img-play-two">
                          <img
                            src="./img/icone-play.png"
                            alt=""
                            className=""
                            style={{  width: '2vw' }}
                          />
                        </div>
        <img
                            src="./img/icone-eco-citoyen3.png"
                            alt=""
                            className="img-act"
                            style={{
                              width: '10vw',
                              marginLeft: '-15%',
                              marginTop:'10%'
                             
                            }}
                          />
        </div>
        </NavLink>
        
        <NavLink to="/module4">
        <div className="col column-btn-two">
        <div className="col img-yello-absolute-two">
                            <div className=" col ">
                              <img
                                src="./img/objet-titre.png"
                                alt=""
                                className="img-yellow"
                                
                              />
                            </div>
                           
                            <div class="col text-img-yellow" >
                            
                            <p className="row"  style={{marginLeft:'-1%',marginTop:'5%'}}
                            dangerouslySetInnerHTML={{
                                  __html: Titre_Module(
                                    sessionStorage.getItem("Lang"),
                                    sessionStorage.getItem("theme"),
                                    3
                                  ),
                                }}/> 
                           
                            </div>
                            
                          
                          </div>
                          <div className="img-play-two">
                          <img
                            src="./img/icone-play.png"
                            alt=""
                            className=""
                            style={{  width: '2vw' }}
                          />
                        </div>
        <img
                            src="./img/icone-eco-citoyen.png"
                            alt=""
                            className="img-act"
                            style={{
                              width: '10vw',
                              marginLeft: '-15%',
                              marginTop:'10%'
                             
                            }}
                          />
        </div>
        </NavLink>
        
        </div>
        
        
      </div>
            </div>
          </div>


      </div> 
      <div className="row africatik-logo mt-1" >
            <Logo />
          </div>
    </div>
  )
  );
};

export default Main;
