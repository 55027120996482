import React from "react";

import { NavLink, useNavigate } from "react-router-dom";

const Accueil = () => {
  const navigate = useNavigate();
  return (
    <>
      <input
        type="image"
        src="./img/bouton_accueil.png"
        alt=""
        className="img-fluid"
        onClick={() => navigate(-1).goBack}
        style={{width:'5vw'}}
      />
    </>
  );
};

export default Accueil;
