
import React, { useEffect, useState } from "react";
import Help from "../components/help";
import Accueil from "../components/home";
import Logo from "../components/logo";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LANG from "../backOffice/properties.json";
import { NativeAudio } from "@capacitor-community/native-audio";
const Module2 = () =>{
   //INITIALISATION
   //Id thème choisie à gerer dynamiquement
var themeID = sessionStorage.getItem("theme");

//Id langue à gerer dynamiquement 0=Français, 1=Lingala, 2=Kikongo, 3=Swahili, 4=Tshiluba
var langID = sessionStorage.getItem("Lang");


  //States changement couleurs
  const [couleur1, setCouleur1] = useState("white");
  var style1 = {
    backgroundColor: couleur1,
  };
  const [couleur2, setCouleur2] = useState("white");

  var style2 = {
    backgroundColor: couleur2,
  };
  const [couleur3, setCouleur3] = useState("white");

  var style3 = {
    backgroundColor: couleur3,
  };
  const [couleur4, setCouleur4] = useState("white");

  var style4 = {
    backgroundColor: couleur4,
  };
  const [couleur5, setCouleur5] = useState("white");

  var style5 = {
    backgroundColor: couleur5,
  };
  const [couleur6, setCouleur6] = useState("white");

  var style6 = {
    backgroundColor: couleur6,
  };
  const [couleur7, setCouleur7] = useState("white");

  var style7 = {
    backgroundColor: couleur7,
  };
  const [couleur8, setCouleur8] = useState("white");

  var style8 = {
    backgroundColor: couleur8,
  };
  const [couleur9, setCouleur9] = useState("white");

  var style9 = {
    backgroundColor: couleur9,
  };
  const [couleur10, setCouleur10] = useState("white");

  var style10 = {
    backgroundColor: couleur10,
  };
  const [couleur11, setCouleur11] = useState("white");

  var style11 = {
    backgroundColor: couleur11,
  };
  const [couleur12, setCouleur12] = useState("white");

  var style12 = {
    backgroundColor: couleur12,
  };
  const [couleur13, setCouleur13] = useState("white");

  var style13 = {
    backgroundColor: couleur13,
  };
  const [couleur14, setCouleur14] = useState("white");

  var style14 = {
    backgroundColor: couleur14,
  };
  const [couleur15, setCouleur15] = useState("white");

  var style15 = {
    backgroundColor: couleur15,
  };
  const [couleur16, setCouleur16] = useState("white");
  var style16 = {
    backgroundColor: couleur16,
  };
  const [couleur17, setCouleur17] = useState("white");

  var style17 = {
    backgroundColor: couleur17,
  };
  const [couleur18, setCouleur18] = useState("white");

  var style18 = {
    backgroundColor: couleur18,
  };
  const [couleur19, setCouleur19] = useState("white");

  var style19 = {
    backgroundColor: couleur19,
  };
  const [couleur20, setCouleur20] = useState("white");

  var style20 = {
    backgroundColor: couleur20,
  };
  
  //Titre du module
  function Titre_Module(IdLang, IdMod, seq) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[IdMod].sequences[seq]
        .title;
    });
    return data;
  }
  //Insertion des audios
  function preloadAudio(id, path) {
    NativeAudio.preload({
      assetId: id,
      assetPath: path,
      audioChannelNum: 1,
      isUrl: false,
    });
  }
  async function handlePlay(id, btnPreload) {
    await document.getElementById(btnPreload).click();
    setTimeout(() => {
      NativeAudio.resume({
        assetId: id,
      });
    }, 200);
  }

  function stopBtn() {
    NativeAudio.unload({ assetId: "musc1" });
    console.log("btn stop cliqué");
  }
  async function stopAudio(Id) {
    NativeAudio.stop({
      assetId: Id,
    });
  }

  function Audios(IdLang, seq, subMod) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[subMod].sequences[seq]
        .pages[0].media_pages[0].audio_file;
    });
    console.log("donnees", data.toString());
    return data.toString();
  }
  //Titre consigne
  function Consigne(IdLang, IdMod, page, seq) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[IdMod].sequences[seq]
        .pages[page].text;
    });
    return data;
  }
  //Fin de partie
  function FIN_DE_PAGE(Idlangue, Idmodule,seq) {
    const data = LANG.map((info) => {
      return info.languages[Idlangue].domains[0].subdomains[Idmodule]
        .sequences[seq].pages[0].title;
    });

    return data;
  }
  //Afficher les auestionneaires et réponses
  //compteur points
  const [countPoint, setCount] = useState(0);

  //compteur questions
  const [countQuestion, setCountQuestion] = useState(0);

  function REPONSES_SELECT(Idlangue, subMod, seq, quest, numRep) {
    const data = LANG.map((info) => {
      return info.languages[Idlangue].domains[0].subdomains[subMod].sequences[
        seq
      ].pages[0].questions[quest].responses[numRep].text;
    });
    return data;
  }
  var nbreQuest = LANG.map((info) => {
    return info.languages[parseInt(langID)].domains[0].subdomains[parseInt(themeID)].sequences[1].pages[0]
      .questions.length;
  });

  var questChoisie = Math.floor(Math.random() * nbreQuest);
  var [choixQuest, setChoixQuest] = useState(questChoisie);

  function controleQuestion(resolution, btn,niveau) {
    console.log('OZOZOZ',countPoint)
    if(niveau == '0'){
      if (resolution === questionnaire1.bonneReponse[0]) {
        //Bonne réponse
        btn.style.backgroundColor = "#46CC00";
        btn.style.color = "white";
  
        setTimeout(() => {
          btn.style.backgroundColor = "white";
          btn.style.color = "#043E7E";
          setReponsesnum(reponsesnum.sort(() => Math.random() - 0.5));
        }, 800);
  
        console.log("blabla: " + countPoint);
  
        console.log("la réponse est bonne");
        setTimeout(() => {
          if(countQuestion <9){
            setCount(countPoint + 1);
            setCountQuestion(countQuestion + 1);
          }
          
        }, 1000);
  
        if (countPoint === 0) {
          setCouleur1("#46CC00");
        }
        if (countPoint === 1) {
          setCouleur2("#46CC00");
        }
        if (countPoint === 2) {
          setCouleur3("#46CC00");
        }
        if (countPoint === 3) {
          setCouleur4("#46CC00");
        }
        if (countPoint === 4) {
          setCouleur5("#46CC00");
          setTimeout(() => {
            ouvrir();
          }, 300);
        }
  
        /*setTimeout(() => {
          window.location.reload();
        }, 1000);*/
      } else {
        console.log("la reponse est mauvaise :");
  
        //Mauvaise réponse
        setTimeout(() => {
          btn.style.backgroundColor = "white";
          btn.style.color = "#043E7E";
          setReponsesnum(reponsesnum.sort(() => Math.random() - 0.5));
        }, 800);
  
        btn.style.backgroundColor = "lightcoral";
        btn.style.color = "white";
        setTimeout(() => {
          if(countQuestion < 9){
            setCount(countPoint + 1);
            setCountQuestion(countQuestion + 1);
          }
          
          
        }, 800);
  
        if (countPoint === 0) {
          setCouleur1("red");
        }
        if (countPoint === 1) {
          setCouleur2("red");
        }
        if (countPoint === 2) {
          setCouleur3("red");
        }
        if (countPoint === 3) {
          setCouleur4("red");
        }
        if (countPoint === 4) {
          setCouleur5("red");
          setTimeout(() => {
            ouvrir();
          }, 300);
        }
      }

    }
    if(niveau == '1'){
      if (resolution === questionnaire1.bonneReponse[0]) {
        //Bonne réponse
        btn.style.backgroundColor = "#46CC00";
        btn.style.color = "white";
  
        setTimeout(() => {
          btn.style.backgroundColor = "white";
          btn.style.color = "#043E7E";
          setReponsesnum(reponsesnum.sort(() => Math.random() - 0.5));
        }, 800);
  
        console.log("blabla: " + countPoint);
  
        console.log("la réponse est bonne");
        setTimeout(() => {
          if(countQuestion <14){
            setCount(countPoint + 1);
            setCountQuestion(countQuestion + 1);
          }
          
        }, 1000);
  
        if (countPoint === 0) {
          setCouleur1("#46CC00");
        }
        if (countPoint === 1) {
          setCouleur2("#46CC00");
        }
        if (countPoint === 2) {
          setCouleur3("#46CC00");
        }
        if (countPoint === 3) {
          setCouleur4("#46CC00");
        }
        if (countPoint === 4) {
          setCouleur5("#46CC00");
        }
        if (countPoint === 5) {
          setCouleur6("#46CC00");
        }
        if (countPoint === 6) {
          setCouleur7("#46CC00");
        }
        if (countPoint === 7) {
          setCouleur8("#46CC00");
        }
        if (countPoint === 8) {
          setCouleur9("#46CC00");
        }
        if (countPoint === 9) {
          setCouleur10("#46CC00");
          setTimeout(() => {
            ouvrir();
          }, 300);
        }
  
        /*setTimeout(() => {
          window.location.reload();
        }, 1000);*/
      } else {
        console.log("la reponse est mauvaise :");
  
        //Mauvaise réponse
        setTimeout(() => {
          btn.style.backgroundColor = "white";
          btn.style.color = "#043E7E";
          setReponsesnum(reponsesnum.sort(() => Math.random() - 0.5));
        }, 800);
  
        btn.style.backgroundColor = "lightcoral";
        btn.style.color = "white";
        setTimeout(() => {
          if(countQuestion < 14){
            setCount(countPoint + 1);
            setCountQuestion(countQuestion + 1);
          }
          
          
        }, 800);
  
        if (countPoint === 0) {
          setCouleur1("red");
        }
        if (countPoint === 1) {
          setCouleur2("red");
        }
        if (countPoint === 2) {
          setCouleur3("red");
        }
        if (countPoint === 3) {
          setCouleur4("red");
        }
        if (countPoint === 4) {
          setCouleur5("red");
        }
        if (countPoint === 5) {
          setCouleur6("red");
        }
        if (countPoint === 6) {
          setCouleur7("red");
        }
        if (countPoint === 7) {
          setCouleur8("red");
        }
        if (countPoint === 8) {
          setCouleur9("red");
        }
        if (countPoint === 9) {
          setCouleur10("red");
          setTimeout(() => {
            ouvrir();
          }, 300);
        }
      }

    } if(niveau == '2'){
      if (resolution === questionnaire1.bonneReponse[0]) {
        //Bonne réponse
        btn.style.backgroundColor = "#46CC00";
        btn.style.color = "white";
  
        setTimeout(() => {
          btn.style.backgroundColor = "white";
          btn.style.color = "#043E7E";
          setReponsesnum(reponsesnum.sort(() => Math.random() - 0.5));
        }, 800);
  
        console.log("blabla: " + countPoint);
  
        console.log("la réponse est bonne");
        setTimeout(() => {
          if(countQuestion <19){
            setCount(countPoint + 1);
            setCountQuestion(countQuestion + 1);
          }
          
        }, 1000);
  
        if (countPoint === 0) {
          setCouleur1("#46CC00");
        }
        if (countPoint === 1) {
          setCouleur2("#46CC00");
        }
        if (countPoint === 2) {
          setCouleur3("#46CC00");
        }
        if (countPoint === 3) {
          setCouleur4("#46CC00");
        }
        if (countPoint === 4) {
          setCouleur5("#46CC00");
        }
        if (countPoint === 5) {
          setCouleur6("#46CC00");
        }
        if (countPoint === 6) {
          setCouleur7("#46CC00");
        }
        if (countPoint === 7) {
          setCouleur8("#46CC00");
        }
        if (countPoint === 8) {
          setCouleur9("#46CC00");
        }
        if (countPoint === 9) {
          setCouleur10("#46CC00");
          setTimeout(() => {
            ouvrir();
          }, 300);
        }
  
        /*setTimeout(() => {
          window.location.reload();
        }, 1000);*/
      } else {
        console.log("la reponse est mauvaise :");
  
        //Mauvaise réponse
        setTimeout(() => {
          btn.style.backgroundColor = "white";
          btn.style.color = "#043E7E";
          setReponsesnum(reponsesnum.sort(() => Math.random() - 0.5));
        }, 800);
  
        btn.style.backgroundColor = "lightcoral";
        btn.style.color = "white";
        setTimeout(() => {
          if(countQuestion < 19){
            setCount(countPoint + 1);
            setCountQuestion(countQuestion + 1);
          }
          
          
        }, 800);
  
        if (countPoint === 0) {
          setCouleur1("red");
        }
        if (countPoint === 1) {
          setCouleur2("red");
        }
        if (countPoint === 2) {
          setCouleur3("red");
        }
        if (countPoint === 3) {
          setCouleur4("red");
        }
        if (countPoint === 4) {
          setCouleur5("red");
        }
        if (countPoint === 5) {
          setCouleur6("red");
        }
        if (countPoint === 6) {
          setCouleur7("red");
        }
        if (countPoint === 7) {
          setCouleur8("red");
        }
        if (countPoint === 8) {
          setCouleur9("red");
        }
        if (countPoint === 9) {
          setCouleur10("red");
          setTimeout(() => {
            ouvrir();
          }, 300);
        }
      }
    }
    
  }
  const [numeroQuestion, setNumeroQuestion] = useState(
    questionsnum(nbreQuest).sort(() => Math.random() - 0.5)
  );

  // Variable objet pour les questions et reponses
  var questionnaire1 = {
    question: LANG.map((info) => {
      return info.languages[langID].domains[0].subdomains[themeID].sequences[1].pages[0]
        .questions[numeroQuestion[countQuestion]].media_questions[0].img_file;

    }),
    reponse: [
      REPONSES_SELECT(langID, themeID, 1, numeroQuestion[countQuestion], 0),
      REPONSES_SELECT(langID, themeID, 1, numeroQuestion[countQuestion], 1),
      REPONSES_SELECT(langID, themeID, 1, numeroQuestion[countQuestion], 2),
    ],
    bonneReponse: REPONSES_SELECT(langID, themeID, 1, numeroQuestion[countQuestion], 0),
  };

  function questionsnum(nbr) {
    var data = [];
    for (let index = 0; index < nbr; index++) {
      data.push(index);
    }

    return data;
  }

  const [show, setShow] = useState(false);
  const fermer = () => setShow(false);
  const ouvrir = () => setShow(true);

  const [reponsesnum, setReponsesnum] = useState(
    [0, 1, 2].sort(() => Math.random() - 0.5)
  );

  //
  function show_niveau_compteur(niveau) {
    if (niveau == "0") {
      return (
        <>
          <div
            className="row cases-pointMod1"
            style={{ margin: "auto", marginTop: "1%", marginBottom: "3%" }}
          >
            <div className="col case" id="1" style={style1}>
              <p>
                <br />
              </p>
            </div>
            <div className="col case" id="2" style={style2}></div>
            <div className="col case " id="3" style={style3}></div>
            <div className="col case" id="4" style={style4}></div>
            <div className="col case" id="5" style={style5}></div>
          </div>
        </>
      );
    }
    if (niveau == "1") {
      return (
        <>
          <div
            className="row cases-pointMod3"
            style={{ margin: "auto", marginTop: "1%", marginBottom: "3%" }}
          >
            <div className="col case" id="1" style={style1}>
              <p>
                <br />
              </p>
            </div>
            <div className="col case" id="2" style={style2}></div>
            <div className="col case " id="3" style={style3}></div>
            <div className="col case" id="4" style={style4}></div>
            <div className="col case" id="5" style={style5}></div>
            <div className="col case" id="6" style={style6}></div>
            <div className="col case" id="7" style={style7}></div>
            <div className="col case" id="8" style={style8}></div>
            <div className="col case" id="9" style={style9}></div>
            <div className="col case" id="10" style={style10}></div>
            
            
          </div>
        </>
      );
    }
    if (niveau == "2") {
      return (
        <>
          <div
            className="row cases-pointMod3"
            style={{ margin: "auto", marginTop: "1%", marginBottom: "3%" }}
          >
            <div className="col case" id="1" style={style1}>
              <p>
                <br />
              </p>
            </div>
            <div className="col case" id="2" style={style2}></div>
            <div className="col case " id="3" style={style3}></div>
            <div className="col case" id="4" style={style4}></div>
            <div className="col case" id="5" style={style5}></div>
            <div className="col case" id="6" style={style6}></div>
            <div className="col case" id="7" style={style7}></div>
            <div className="col case" id="8" style={style8}></div>
            <div className="col case" id="9" style={style9}></div>
            <div className="col case" id="10" style={style10}></div>
          </div>
        </>
      );
    }
  }
  return (
    <div className="container-fluid">
      <div className="col">
        <div
          className="row mt-4 "
          style={{
            fontSize: "2vw",
            fontWeight: "bold",
            color: "#00884A",
            textTransform: "uppercase", 
          }}
        >
          <div className="col-1 " >
            <div className="accueil">
              <Accueil />
            </div>
          </div>
          <div
            className="col"
            style={{ textAlign: "center",marginTop:'2%' }}
            dangerouslySetInnerHTML={{
              __html: Titre_Module(langID, themeID, 1),
            }}
          />
          <div className="col-1"></div>
        </div>
        <Modal show={show} onHide={fermer} size="lg" style={{ width: "100%" }}>
          <div className="row" id="finpartie">
            <div className="row finpartie-text" style={{ marginBottom: "-1%" }}>
              <p className="h2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: FIN_DE_PAGE(langID, 0, 4),
                  }}
                />
              </p>
            </div>
            <div className="col mb-5 mt-5">
              <div className="col">{show_niveau_compteur(themeID)}</div>
            </div>
            <div
              className="row finpartie-btn-groupe mb-5"
              style={{ marginTop: "-5%" }}
            >
              <div className="col center finpartie-btn">
                <NavLink to="" onClick={() => window.location.reload()}>
                  <img
                    src="./img/bouton_retour.png"
                    alt="Récommencer"
                    className=""
                    style={{ width: "5vw", marginLeft: "80%" }}
                  />
                </NavLink>
              </div>
              <div className="col center finpartie-btn">
                <Accueil />
              </div>
            </div>
          </div>
        </Modal>
        <div style={{ }}>{show_niveau_compteur(themeID)}</div>
        <div className="row centrer">
          <div className="col">
            <div
              className="row"
              style={{
                margin: "auto",
                backgroundColor: "#00884A",
                height: "4vw",
                marginTop: "-2vw",
              }}
            >
              <div className="col-3"></div>
              <div className="col " style={{}}>
                <p
                  className="h4 texte-choix-reponse pt-2 "
                  style={{ textAlign:'center' ,color: "#fff",marginLeft:'3%' }}
                >
                  <div
                    className="col body-text"
                    style={{ textAlign: "center",marginTop:'1%' }}
                    dangerouslySetInnerHTML={{
                      __html: Consigne(langID, themeID, 0, 1),
                    }}
                  />
                </p>
              </div>
              
              <div className="col  " style={{ marginTop: "1%",marginLeft:'-15%' }}>
                <div className="col ">
                  <button
                    onClick={() =>
                      preloadAudio("musc1", Audios(langID, 1, themeID))
                    }
                    id="preload1"
                    style={{ display: "none" }}
                  >
                    Preload
                  </button>
                </div>
                <div className="col width-barre-titre">
                  <input
                    type="image"
                    onClick={() =>
                      handlePlay(
                        "musc1",

                        "preload1"
                      )
                    }
                    id="btn-play1"
                    src="./img/bouton-son.png"
                    style={{
                      width: "4vw",
                      marginLeft: "8vw",
                      marginTop: "-1vw",
                      position: "absolute",
                    }}
                  />
                </div>
                
              </div>
            </div>

            <div
              className="row" style={{marginTop:'-10%'}}
            >
              <div
                className="row "
                style={{
                  width: "50%",
                  marginLeft: "5%",
                  marginTop: "13%",
                
                  position: "relative",
                }}
              >
                    <div className="col" >
                          <img
                          src={"/imgs/" + questionnaire1.question }
                          alt="Récommencer"
                          className=""
                          style={{width:'80%',height:'90%',borderRadius:'5%'}}
                        /> 
                    </div>
                  
              </div>
              <div className="col" style={{marginLeft:'-20%'}}>
                <div className="col reponses-quiz" style={{ marginTop: "23%" }}>
                  <div className="row">
                    <div className="col">
                      <button
                        style={{ width: "40%", color: "#60523B" }}
                        onClick={(e) =>
                          controleQuestion(
                            questionnaire1.reponse[reponsesnum[0]][0],
                            document.getElementById("btn-reponse1"),
                            themeID
                          )
                        }
                        id="btn-reponse1"
                      >
                        <p
                          style={{ color: "#60523B", fontSize: "1.5vw" }}
                          dangerouslySetInnerHTML={{
                            __html: questionnaire1.reponse[reponsesnum[0]][0],
                          }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        style={{ width: "40%" }}
                        onClick={(e) =>
                          controleQuestion(
                            questionnaire1.reponse[reponsesnum[1]][0],
                            document.getElementById("btn-reponse2"),
                            themeID
                          )
                        }
                        id="btn-reponse2"
                        disabled={false}
                      >
                        <p
                          style={{ color: "#60523B", fontSize: "1.5vw" }}
                          dangerouslySetInnerHTML={{
                            __html: questionnaire1.reponse[reponsesnum[1]][0],
                          }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        style={{ width: "40%" }}
                        onClick={(e) =>
                          controleQuestion(
                            questionnaire1.reponse[reponsesnum[2]][0],
                            document.getElementById("btn-reponse3"),
                            themeID
                          )
                        }
                        id="btn-reponse3"
                      >
                        <p
                          style={{ color: "#60523B", fontSize: "1.5vw" }}
                          dangerouslySetInnerHTML={{
                            __html: questionnaire1.reponse[reponsesnum[2]][0],
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Module2